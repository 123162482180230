const DemoVideo = () => {
  return (
    <>
      <script
        src="https://fast.wistia.com/embed/medias/nwksv1unzc.jsonp"
        async
      ></script>
      <script
        src="https://fast.wistia.com/assets/external/E-v1.js"
        async
      ></script>
      <div className="relative rounded-lg shadow-lg shadow-blue-900 cursor-pointer w-[300px] h-fit -mt-14 mb-6 md:-mt-14 md:-mb-14 overflow-hidden">
        <div
          className="wistia_responsive_padding relative"
          style={{ padding: '56.25% 0 0 0' }}
        >
          <div className="wistia_responsive_wrapper h-full left-0 absolute top-0 w-full">
            <span className="wistia_embed wistia_async_nwksv1unzc popover=true popoverAnimateThumbnail=true videoFoam=true inline-block h-full relative w-full">
              &nbsp;
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default DemoVideo
