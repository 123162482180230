import SmallKey from '@keyhero/core/src/components/SmallKey'
import { useEffect, useState } from 'react'
import { isMacOs } from 'react-device-detect'
import { useForm } from 'react-hook-form'
import DemoVideo from './DemoVideo'

const MOUSE_TIME = 3.129
const KEYBOARD_TIME = 1.362
const CLICKS = 2000
const WORK_DAYS = 250

const calculateSavings = (hours) => {
  const hoursPerDay = hours / 5
  const clicksPerDay = CLICKS * (hoursPerDay / 8)
  const secondsSavedPerDayWithShortcuts =
    (MOUSE_TIME - KEYBOARD_TIME) * clicksPerDay
  const minsSavedPerDay = secondsSavedPerDayWithShortcuts / 60
  const hoursSavedPerDay = minsSavedPerDay / 60
  const hoursSavedPerYear = hoursSavedPerDay * WORK_DAYS
  const daysSavedPerYear = hoursSavedPerYear / hoursPerDay
  return { daysSavedPerYear, minsSavedPerDay }
}

const formatMinutes = (minutes) => {
  if (!minutes) {
    return '0 mins'
  } else if (minutes > 60) {
    const hours = minutes / 60.0
    const formattedHours = hours.toFixed(1).replace('.0', '')
    return `${formattedHours} hours`
  } else {
    return `${minutes} mins`
  }
}

const NewLandingHero = ({ handleClickStart }) => {
  const { minsSavedPerDay: initialMinsSaved } = calculateSavings(40)
  const [minsSaved, setMinsSaved] = useState(Math.round(initialMinsSaved))

  const { register, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      hours: 40,
      salary: '40,000',
    },
  })

  useEffect(() => {
    const subscription = watch((value) => {
      const { minsSavedPerDay } = calculateSavings(value.hours)
      setMinsSaved(Math.round(minsSavedPerDay))
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const getStyles = (minsSaved) => {
    if (minsSaved <= 60) {
      return {
        hoursToShow: 2,
        hourRows: 3,
        hourRowRem: 6,
        minRows: 36,
        minRowRem: 24,
        endMinRow: 26,
      }
    } else if (minsSaved <= 90) {
      return {
        hoursToShow: 3,
        hourRows: 5,
        hourRowRem: 4.5,
        minRows: 60,
        minRowRem: 24,
        endMinRow: 50,
      }
    } else {
      return {
        hoursToShow: 4,
        hourRows: 7,
        hourRowRem: 3,
        minRows: 84,
        minRowRem: 24,
        endMinRow: 74,
      }
    }
  }

  const { hoursToShow, hourRows, hourRowRem, minRows, minRowRem, endMinRow } =
    getStyles(minsSaved)

  return (
    <div>
      <div className="mx-auto max-w-screen-lg px-4 lg:px-0">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="my-2 md:my-5 col-span-7 col-start-1 text-center md:text-left">
            <h1 className="text-4xl sm:text-6xl font-extrabold tracking-tight text-gray-900">
              Work faster.
            </h1>
            <h2 className="mt-1 sm:mt-2 text-xl sm:text-4xl text-gray-900">
              We can teach you the shortcuts.
            </h2>
            <p className="mt-2 sm:mt-4 text-md hidden md:block">
              &#40;Here&apos;s one: press{' '}
              <SmallKey name={isMacOs ? 'Cmd' : 'Ctrl'} />
              <SmallKey name="D" /> to bookmark this page.&#41;
            </p>
            <div className="mt-5 sm:mt-10">
              <h1>
                <span className="mt-1 block text-xl sm:text-4xl">
                  <span className="block text-gray-500">
                    If you work{' '}
                    <input
                      type="number"
                      {...register('hours', {
                        min: 0,
                        max: 168,
                      })}
                      max={168}
                      min={0}
                      className="text-center border-0 border-b-4 active:border-b-2 border-blue-500 focus:ring-0 text-xl sm:text-4xl font-extrabold w-10 sm:w-16 global--hide-spin-buttons px-0 text-gray-900"
                    />{' '}
                    hours a week...
                  </span>
                </span>
              </h1>
              <h2 className="mt-3 md:mt-4 text-xl sm:text-4xl text-gray-500">
                Keyhero saves you{' '}
                <span className="highlight leading-4 text-gray-900 px-2 py-1">
                  {formatMinutes(minsSaved)}
                </span>{' '}
                a day.
              </h2>
              <button
                onClick={() => {
                  handleClickStart()
                }}
                className="bg-blue-600 text-white hover:bg-blue-700 block py-4 px-8 border border-transparent rounded-md shadow-sm text-center text-lg font-bold w-full md:w-auto mt-10"
              >
                Join the waitlist
              </button>
            </div>
          </div>
          <div className="mt-10 lg:mt-0 col-span-5 col-start-8 h-full flex flex-col">
            <div className="my-auto">
              <div className="flex w-full flex-auto ring-1 ring-gray-100 rounded-md">
                <div className="w-14 flex-none bg-white ring-1 ring-gray-100 rounded-tl-md rounded-bl-md" />
                <div className="grid flex-auto grid-cols-1 grid-rows-1">
                  {/* Horizontal lines */}
                  <div
                    className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                    style={{
                      gridTemplateRows: `repeat(${hourRows}, minmax(${hourRowRem}rem, 1fr))`,
                    }}
                  >
                    <div className="row-end-1 h-7"></div>
                    {hoursToShow === 4 && (
                      <>
                        <div>
                          <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-500">
                            2PM
                          </div>
                        </div>
                        <div />
                      </>
                    )}
                    {hoursToShow >= 3 && (
                      <>
                        <div>
                          <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-500">
                            3PM
                          </div>
                        </div>
                        <div />
                      </>
                    )}
                    <div>
                      <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-500">
                        4PM
                      </div>
                    </div>
                    <div />
                    <div>
                      <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-500">
                        5PM
                      </div>
                    </div>
                  </div>

                  {/* Events */}
                  <ol
                    className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
                    style={{
                      gridTemplateRows: `1.55rem repeat(${minRows}, minmax(0, 1fr)) auto`,
                    }}
                  >
                    <li
                      className="relative mt-px flex"
                      style={{
                        gridRow: `${Math.ceil(
                          endMinRow - Math.ceil(minRowRem * (minsSaved / 60.0)),
                        )} / span ${Math.ceil(minRowRem * (minsSaved / 60.0))}`,
                      }}
                    >
                      <div className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-blue-50 p-2 text-sm leading-5 hover:bg-blue-100 justify-center items-center">
                        <p className="order-1 font-semibold text-blue-700">
                          How would you spend an extra{' '}
                          {formatMinutes(minsSaved)}?
                        </p>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-20 w-full bg-blue-800 px-4">
        <div className="mx-auto max-w-screen-lg py-10 flex flex-col items-center md:items-start md:flex-row  md:space-x-10 text-center md:text-left">
          <DemoVideo />
          <div className="self-center">
            <h3 className="text-xl md:text-[1.75rem] font-bold text-white">
              Watch how Keyhero teaches you shortcuts.
            </h3>
            <p className="text-xl text-blue-100">
              &#40;No sound needed.&#41;
              {false && (
                <span>
                  {' '}
                  Or{' '}
                  <a
                    href="#science"
                    className="text-blue-100 hover:text-blue-200 underline"
                  >
                    skip to the science
                  </a>
                  .
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewLandingHero
