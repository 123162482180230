import { ReactElement, useRef, useState } from 'react'
import { GetStaticProps } from 'next'
import { dehydrate, QueryClient } from 'react-query'
import { NextSeo } from 'next-seo'

import Layout from '@components/Layout'
import type { NextPageWithLayout } from './_app'
import { useCourses } from '@hooks/generated'
import WaitlistForm from '@components/WaitlistForm'
import Apps from '@components/Marketing/Apps'
import Testimonials from '@components/Marketing/Testimonials'
import LatestShortcutPractice from '@components/Marketing/LatestShortcutPractice'
import NewLandingHero from '@components/Marketing/NewLandingHero'
import {
  faChalkboardUser,
  faFire,
  faKeyboard,
  faWatch,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const features = [
  {
    icon: faWatch,
    iconColor: 'text-indigo-600',
    name: '5 minutes a day',
    description:
      'You only need a few mins a day to learn more than 100 shortcuts every month.',
  },
  {
    icon: faKeyboard,
    iconColor: 'text-lime-600',
    name: 'Type to learn',
    description:
      'Just type the keyboard shortcuts and learn to build your muscle memory.',
  },
  {
    icon: faChalkboardUser,
    iconColor: 'text-blue-600',
    name: 'Personalized learning',
    description:
      'We adapt to your learning pace and the time you have available to practice.',
  },
  {
    icon: faFire,
    iconColor: 'text-amber-600',
    name: 'Build your habit',
    description:
      'Track your progress with daily streaks and set reminders to come back and practice.',
  },
]

const IndexPage: NextPageWithLayout = () => {
  const waitlistRef = useRef<HTMLDivElement>(null)
  const [focus, setFocus] = useState(false)

  const handleClickStart = () => {
    if (waitlistRef.current) {
      waitlistRef.current.scrollIntoView({
        block: 'center',
        inline: 'center',
      })
    }

    setFocus(true)
  }

  return (
    <>
      <NextSeo
        title="Keyhero - Learn keyboard shortcuts"
        description="Learn shortcuts for all your tools in less than 5 minutes a day."
      />
      <div>
        <NewLandingHero handleClickStart={handleClickStart} />
        <div className="px-4 mx-auto max-w-screen-lg">
          <div className="my-16 sm:my-20">
            <dl className="space-y-0 grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8">
              {features.map((feature) => (
                <div
                  key={feature.name}
                  className="text-left col-span-1 mb-6 lg:mb-0"
                >
                  <dt>
                    <div className={`text-4xl ${feature.iconColor}`}>
                      {/* <feature.icon className="h-6 w-6" aria-hidden="true" /> */}
                      <FontAwesomeIcon icon={feature.icon} swapOpacity />
                    </div>
                    <p className="mt-5 text-lg leading-6 font-bold text-gray-900">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="mt-2 text-md">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="my-16 sm:my-20" ref={waitlistRef}>
            <div className="max-w-xl mx-auto">
              <h2 className="text-xl font-bold text-gray-900 uppercase text-center">
                Join the waitlist now
              </h2>
              <p className="my-2 mb-6 text-lg text-center">
                Invites are being sent out daily
              </p>
              <WaitlistForm
                focus={focus}
                onFocus={() => {
                  setFocus(false)
                }}
              />
            </div>
          </div>
          <div className="my-16 sm:my-20">
            <LatestShortcutPractice />
          </div>
          <div>
            <Testimonials />
          </div>
          <div className="my-16 sm:mt-20">
            <Apps />
          </div>
          <div className="my-16 sm:my-20">
            <div className="max-w-xl mx-auto">
              <h2 className="text-xl font-bold text-gray-900 uppercase text-center">
                Join the waitlist
              </h2>
              <WaitlistForm />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const queryClient = new QueryClient()

  await queryClient.prefetchQuery(useCourses.getKey(), useCourses.fetcher())

  return {
    props: {
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
    },
    revalidate: 300,
  }
}

IndexPage.getLayout = function getLayout(page: ReactElement) {
  return <Layout classNameOverride="my-8 mx-auto">{page}</Layout>
}

export default IndexPage
